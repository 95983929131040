<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import { GetDataMethods, PostDataMethods } from "@/state/helpers";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Users",
      items: [
        {
          text: "Wesal",
        },
        {
          text: "Users",
          active: true,
        },
      ],
      isVisible: false,
      countPage: 1,
      totalRows: 1,
      userData: [],
      currentPage: 0,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: "",
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      userId: 0,
      ErrorDelete: null,
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "username", sortable: false, label: "User Name" },
        { key: "phone", sortable: false, label: "Phone" },
        { key: "role", sortable: false, label: "Role" },
        { key: "points", sortable: false, label: "Points" },
        { key: "credit", sortable: false, label: "Credit" },
        { key: "deposit", sortable: false, label: "Deposit" },
        { key: "user_id", sortable: false, label: "User Id" },
        { key: "created_at", sortable: false, label: "Created At" },
        { key: "updated_at", sortable: false, label: "Updated At" },
        { key: "deleted_at", sortable: false, label: "Deleted At" },
        { key: "action_id", sortable: false, label: "Action" },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     *
     */
    rows() {
      return this.countPage
    }

  },

  mounted() {
    this.GetUsersData();
  },



  watch: {
    currentPage: function () {
      this.GetUsersData(this.currentPage, this.filter);
    },
  },


  methods: {
    ...GetDataMethods,
    ...PostDataMethods,

    GetUsersData(page, filter) {
      this.getData("get_all_users?page=" + page + "&search" + filter).then((res) => {
        this.userData = res.data.data.users;
        this.countPage = res.data.data.countPage;
      });
    },

    storeUserId(key) {
      this.userId = key;
    },

    DeleteUser() {
      this.$refs["my-modal"].hide();
      this.deleteData("delete_user/" + this.userId).then(res => {
        if (res.data.status) {
          this.makeToast("danger ", "Success", res.data.message);
          this.GetUsersData(this.currentPage);
        } else {
          this.makeToast("danger ", "Fail", res.data.message);
        }
      })
    },

    makeToast(variant = null, body = null, title = null) {
      this.$bvToast.toast(title, {
        title: `${body}`,
        variant: variant,
        solid: true,
      });
    },


  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">All Users</a>
                </template>
                <div class="row mt-4">
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search"
                          class="form-control form-control-sm ml-2"></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>

                <div class="table-responsive">
                  <b-table class="table-centered" id="user-tab" :items="userData" :fields="fields" responsive="sm"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :per-page="perPage"
                    :filter-included-fields="filterOn">
                    <template v-slot:cell(updated_at)="data">
                      <div>{{ data.value | formatDate }}</div>
                    </template>

                    <template v-slot:cell(created_at)="data">
                      <div>{{ data.value | formatDate }}</div>
                    </template>

                    <template v-slot:cell(deleted_at)="data">
                      <div class="badge-soft-danger" style="background-color: rgb(251 245 246 / 18%)">
                        {{ data.value | formatDate }}</div>
                    </template>

                    <template v-slot:cell(action_id)="data">
                      <router-link :to="{ name: 'user', params: { id: data.value } }"
                        class="mdi mdi-eye mr-2 font-size-18" v-b-tooltip.hover title="Details">
                      </router-link>

                      <router-link :to="{ name: 'EditUser', params: { id: data.value } }"
                        class="mdi mdi-pencil mr-2 font-size-18" v-b-tooltip.hover title="Edit">
                        {{ Edit }}
                      </router-link>

                      <a class="text-danger" v-on:click.left="storeUserId(data.value)" v-b-modal.modal-backdrop
                        variant="primary" v-b-tooltip.hover title="Delete">
                        <i class="mdi mdi-trash-can mr-2 font-size-18"></i>
                      </a>
                    </template>
                  </b-table>

                  <b-modal ref="my-modal" size="sm" hide-footer no-close-on-backdrop id="modal-backdrop" title="Delete"
                    title-class="font-18">
                    <p>Are you sure to delete this user ?</p>
                    <b-button class="mt-2 mr-2" variant="outline-danger" @click="DeleteUser()">OK</b-button>
                    <b-button class="mt-2" variant="outline-primary"
                      @click="$bvModal.hide('modal-backdrop')">Cancel</b-button>
                  </b-modal>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :totalRows="rows" :per-page="perPage"
                          aria-controls="user-tab">
                        </b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
